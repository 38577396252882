import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Form, Formik } from "formik";
import { AuthContext } from "Contexts/AuthContext";

import Button from "components/Button";
import { CreateRefillOrderRequest, DeleteRefillOrderCartRequest } from "apis";

import greenEyeIcon from "assets/images/home/green-eye-icon.svg";
import redTrashIcon from "assets/images/home/red-trash-icon.svg";
import editRefillIcon from "assets/images/home/edit-refill.svg";

type ActivePrescriptionProps = {
  prescription: any;
  onRemoveFromCart: () => void;
};

const ActivePrescription = ({
  prescription,
  onRemoveFromCart,
}: ActivePrescriptionProps) => {
  const { reloadUser, setShowRefillInProgress, setShowDuplicateCartModal } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const hasActiveCart = prescription.has_an_active_cart;
  const cartsInContactStage = prescription.carts_in_contact_stage;
  const orderQty = hasActiveCart ? prescription.quantity_dispensed : 0;

  const deleteRefillCart = () => {
    DeleteRefillOrderCartRequest(prescription.id)
      .then(() => {
        onRemoveFromCart();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        reloadUser();
      });
  };

  return (
    <div className="account-card d-flex justify-content-between">
      <div className="card-cols row">
        <div className="card-col sub-heading">
          {prescription.product_variant_fullname}
        </div>
      </div>
      <div className="card-col">
        {hasActiveCart && (
          <Link
            to={"/account/current-order"}
            className="btn btn-ghost btn-green text-brand-primary text-capitalize">
            <span className="refill-buttons-stage d-flex justify-content-center">
              <ReactSVG
                src={greenEyeIcon}
                className="refill-btn-icon"
                wrapper="div"
              />
              View Order Details
            </span>
          </Link>
        )}
        {cartsInContactStage && (
          <Button
            classes="btn-red text-brand-primary text-capitalize"
            fullWidth={true}
            color="ghost"
            onClick={() => {
              deleteRefillCart();
              setShowRefillInProgress(false);
            }}>
            <span className="refill-buttons-stage d-flex justify-content-center">
              <ReactSVG
                src={redTrashIcon}
                className="refill-btn-icon"
                wrapper="div"
              />
              Remove From Cart
            </span>
          </Button>
        )}
        {!hasActiveCart && !cartsInContactStage && (
          <Formik
            initialValues={{
              branch: "refill_request",
              step: "order_verification",
              product_variant_id: prescription.product_variant.id,
              delivery_type: "standard",
              quantity: prescription.product_variant.min_order_quantity,
              max_quantity: prescription.rx_total_quantity_remaining,
              rx_id: prescription.rx_id,
            }}
            onSubmit={(values) => {
              CreateRefillOrderRequest(values)
                .then((res) => {
                  navigate("/refill-product-detail");
                })
                .catch((err) => {
                  setShowDuplicateCartModal(true);
                });
            }}>
            {() => (
              <Form className="new_cart">
                <Button
                  classes="btn-blue text-brand-primary text-capitalize"
                  color="ghost"
                  type="submit">
                  <span className="refill-buttons-stage d-flex justify-content-center">
                    <ReactSVG
                      src={editRefillIcon}
                      className="refill-btn-icon"
                      wrapper="div"
                    />
                    Order Refill
                  </span>
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default ActivePrescription;
