import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import { useRollbar } from "@rollbar/react";
import { AuthContext } from "./AuthContext";
import {
  CreateCartRequest,
  FetchCartRequest,
  UpdateCartRequest,
  DestroyCartRequest,
  WelcomePageRequest,
  SendIntroQuestionsToPocketmedRequest,
  VisitConsentNextStepRequest,
  VisitIntroNextStepRequest,
  PatientInfoNextStepRequest,
  QuestionnaireNextStepRequest,
  ProductDetailNextStepRequest,
  ShippingNextStepRequest,
  IdUploadNextStepRequest,
  SelfieNextStepRequest,
  ApplyDiscountRequest,
  RemoveDiscountRequest,
  ResetQuestionnaireRequest,
  CartsPreviousStepRequest,
  CreateSlugCartRequest,
  MarketingNextStepRequest,
} from "../apis";

import { CartPreviousPath } from "../helpers/BackButton";
import useCurrentUser from "hooks/useCurrentUser";
import ReactPixel from "react-facebook-pixel";

export type CartContextValue = {
  latestCart?: CartType;
  cartProductVariant?: ProductVariantType;
  shouldQuestionnaireBack?: boolean;
  currentQuestionId: number;
  questionProgressBar: number;
  setLatestCart: Dispatch<SetStateAction<CartType | undefined>>;
  setCartProductVariant: Dispatch<
    SetStateAction<ProductVariantType | undefined>
  >;
  CreateCart: (values: { variant: string; qty?: string }) => void;
  CreateSlugCart: (values: { slug: string; qty: string | null }) => void;
  ReloadCart: () => any;
  UpdateCart: (cart: CartType) => any;
  DestroyCart: (cartId: number) => void;
  WelcomePage: () => void;
  MarketingNextStep: (cartId: number | undefined) => void;
  SendIntroQuesToPocketmed: () => void;
  VisitConsentNextStep: (values: { user: {}; visitUuid: string }) => void;
  VisitIntroNextStep: (cartId: number) => void;
  PatientInfoNextStep: (cartId: number) => void;
  QuestionnaireNextStep: (cartId: number, values: {}) => void;
  ProductDetailNextStep: (cartId: number) => void;
  ShippingNextStep: (cartId: number, resetter: () => any) => void;
  IdUploadNextStep: (cartId: any, skipId: boolean) => void;
  SelfieNextStep: (cartId: any, skipSelfie: boolean) => void;
  ApplyDiscount: (values: { cart_id: number; code: string }) => any;
  RemoveDiscount: (cartId: number) => any;
  PreviousCartStep: () => any;
  setShouldQuestionnaireBack: Dispatch<SetStateAction<boolean>>;
  setCurrentQuestionId: Dispatch<SetStateAction<number>>;
  setQuestionProgressBar: Dispatch<SetStateAction<number>>;
};

export type ProductVariantType = {
  id: number;
  approved_for: string;
  brand: string;
  fullname: string;
  created_at: string;
  discarded_at: string;
  dosage_unit: string;
  dosage_value: number;
  dosage: number;
  product_name: string;
  gcn: number;
  min_order_quantity: number;
  product_id: number;
  slug: string;
  unit: string;
  updated_at: string;
};

export type CartType = {
  id: number;
  token?: string;
  branch?: string;
  step?: string;
  user_id?: number;
  landing_page_id?: number;
  product_variant_id?: number;
  price?: number;
  quantity?: number;
  discarded_at?: string;
  created_at?: string;
  updated_at?: string;
  shipping_address_id?: number;
  doctor_id?: number;
  pharmacy_id?: number;
  product_id?: number;
  delivery_type?: string;
  final_price?: number;
  final_quantity?: number;
  delivery_price?: number;
  final_delivery_price?: number;
  total_price?: number;
  stage?: string;
  stage_set_at?: string;
  submitted_at?: string;
  rx_type?: string;
  when_needed?: string;
  order_id?: number;
  manual_text_messages_count?: number;
  automated_text_messages_count?: number;
  prescribed_product_id?: number;
  prescribed_product_variant_id?: number;
  time_zone?: string;
  manual_user_mails_count?: number;
  automated_user_mails_count?: number;
  manual_faxes_count?: number;
  automated_faxes_count?: number;
  terms_of_service?: boolean;
  skip_payment?: boolean;
  user_fax_status?: string;
  previous_stage?: string;
  max_quantity?: number;
  authorized_transaction_id?: string;
  received_pocketmed_response?: boolean;
  rx_id?: string;
  origin?: number;
  provider_fee?: number;
  visit_uuid?: string;
  notify_admin_email_sent?: boolean;
  shipping_address?: any;
  delivery_option?: any;
  simple_drug_name?: string;
  per_tablet_cost?: number;
  product_variant?: ProductVariantType;
  marketing?: boolean;
  product_name_with_brand?: string;
};

export type OrderType = {
  id: number;
  invoice_number: string;
  state: string;
  shipping_address_id: number;
  delivery_type: string;
  delivery_price: number | string;
  final_delivery_price: number | string;
  total_price: number | string;
  final_price: number | string;
  terms_of_service: boolean;
  standard_delivery_price: number | string;
  express_delivery_price: number | string;
  errors: [];
  delivery_label: string;
  admin_id: number;
  shipping_address?: any;
  delivery_option?: any;
};

export const CartContext = createContext<CartContextValue>({
  latestCart: undefined,
  cartProductVariant: undefined,
  shouldQuestionnaireBack: false,
  currentQuestionId: 0,
  questionProgressBar: 0,
  setLatestCart: () => {},
  setCartProductVariant: () => {},
  CreateCart: () => {},
  CreateSlugCart: () => {},
  ReloadCart: () => {},
  UpdateCart: () => {},
  DestroyCart: () => {},
  WelcomePage: () => {},
  SendIntroQuesToPocketmed: () => {},
  VisitConsentNextStep: () => {},
  VisitIntroNextStep: () => {},
  PatientInfoNextStep: () => {},
  QuestionnaireNextStep: () => {},
  ProductDetailNextStep: () => {},
  ShippingNextStep: () => {},
  IdUploadNextStep: () => {},
  SelfieNextStep: () => {},
  ApplyDiscount: () => {},
  RemoveDiscount: () => {},
  PreviousCartStep: () => {},
  setShouldQuestionnaireBack: () => {},
  setCurrentQuestionId: () => {},
  setQuestionProgressBar: () => {},
  MarketingNextStep: () => {},
});

const CartContextProvider = ({ children }: any) => {
  const {
    authenticated,
    reloadUser,
    setLoading,
    setShowDuplicateCartModal,
    loginCheck,
    setLoginCheck,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [latestCart, setLatestCart] = useState<CartType | undefined>(undefined);
  const [cartProductVariant, setCartProductVariant] = useState<
    ProductVariantType | undefined
  >(undefined);
  const [shouldQuestionnaireBack, setShouldQuestionnaireBack] =
    useState<boolean>(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<number>(0);
  const [questionProgressBar, setQuestionProgressBar] = useState<number>(0);
  const rollbar = useRollbar();
  const currentUser = useCurrentUser();

  useEffect(() => {
    /*
    We have introduced a new 'null' state for 'authenticated' which implies that the processing is still going on; the only time
    we decide to either show 404 or show the correct route is when it is either set to false or true.

    In the case of where we fetched and set the user's latest cart, we were setting 'loading' to false in it's 'finally' block which
    was causing the mini 404 flash that should be removed now.
    */
    if (!isNull(authenticated) && !isEmpty(latestCart)) {
      setLoading(false);
    }

    if (!authenticated) {
      // If user is not authenticated, remove cart
      setLatestCart(undefined);
      setCartProductVariant(undefined);
      return;
    }

    // TODO: This was causing error while cart is assigned after logged in unable to set users latest cart
    if (!isEmpty(latestCart) && !loginCheck) {
      // if cart is not empty and user is authenticated, return
      return;
    }

    FetchCartRequest() // fetch cart if latestCart is empty and user is autheticated
      .then((res) => {
        const cart = res.data.cart;

        if (!isEmpty(cart)) {
          setLatestCart(res.data.cart);
        }
        setCartProductVariant(res.data.product_variant);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoginCheck(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const CreateCart = async (values: { variant: string; qty?: string }) => {
    const CartToken = localStorage.getItem("CartToken") ?? "";

    return CreateCartRequest({
      variant: values.variant,
      cart_token: CartToken,
      qty: values.qty,
    })
      .then((res) => {
        const cart = res.data.cart;

        if (res.data.pm_user_jti) {
          localStorage.setItem("PmJti", res.data.pm_user_jti);
        }

        if (res.data.duplicate_cart) {
          rollbar.info(`Duplicate Cart: ${res.data.duplicate_cart.message}`);
          setShowDuplicateCartModal(true);
          return;
        }

        if (!isEmpty(cart)) {
          setLatestCart(cart);
          setCartProductVariant(res.data.product_variant);

          localStorage.setItem("CartToken", cart.token);
          localStorage.removeItem("intro-answers");

          navigate("/welcome");
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.duplicate_cart) {
          setShowDuplicateCartModal(true);
        }
      });
  };

  const CreateSlugCart = async (values: {
    slug: string;
    qty: string | null;
  }) => {
    const CartToken = localStorage.getItem("CartToken") ?? "";

    return CreateSlugCartRequest({
      slug: values.slug,
      qty: values.qty,
      cart_token: CartToken,
    })
      .then((res) => {
        const cart = res.data.cart;

        if (res.data.duplicate_cart) {
          rollbar.info(`Duplicate Cart: ${res.data.duplicate_cart.message}`);
          setShowDuplicateCartModal(true);
          return;
        }

        setLatestCart(cart);
        setCartProductVariant(res.data.product_variant);

        localStorage.setItem("CartToken", cart.token);
        localStorage.removeItem("intro-answers");
      })
      .catch((err) => {
        rollbar.error(err);
        if (err.response.data.duplicate_cart) {
          setShowDuplicateCartModal(true);
        }
      });
  };

  const ReloadCart = async () => {
    FetchCartRequest()
      .then((res) => {
        setLatestCart(res.data.cart);
        setCartProductVariant(res.data.product_variant);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateCart = async (cart: CartType) => {
    return UpdateCartRequest(cart)
      .then((res) => {
        const cart = res.data.cart;

        if (!isEmpty(cart)) {
          setLatestCart(cart);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const DestroyCart = async (cartId: number | undefined) => {
    return DestroyCartRequest(cartId)
      .then((res) => {
        setLatestCart(undefined);
        reloadUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const WelcomePage = async () => {
    const cartId = latestCart?.id.toString() || "";
    const CartToken = localStorage.getItem("CartToken") || "";

    return WelcomePageRequest(cartId, CartToken)
      .then((res) => {
        window._mfq.push(["setVariable", "CartId", cartId]);

        navigate("/intro-questions");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const MarketingNextStep = async (cartId: number | undefined) => {
    return MarketingNextStepRequest(cartId)
      .then((res) => {
        const referrer = localStorage.getItem("referrer");

        if (!isEmpty(res.data.cart)) {
          setLatestCart(res.data.cart);
        }

        if (res.status === 200) {
          ReactPixel.fbq(
            "track",
            "AddToCart",
            {},
            { eventID: "fb_add_to_cart" }
          );
          navigate("/welcome");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SendIntroQuesToPocketmed = async () => {
    const cartId = latestCart?.id.toString() || "";

    return SendIntroQuestionsToPocketmedRequest(cartId)
      .then((res) => {
        if (res.data.status.code === 200) {
          navigate(res.data.redirect_path);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const VisitConsentNextStep = async (values: {
    user: {};
    visitUuid: string;
  }) => {
    const cartId = latestCart?.id.toString() || "";

    return VisitConsentNextStepRequest(values.user, values.visitUuid, cartId)
      .then((res) => {
        setLatestCart(res.data.cart);

        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const VisitIntroNextStep = async (cartId: number) => {
    return VisitIntroNextStepRequest(cartId)
      .then((res) => {
        setLatestCart(res.data.cart);
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PatientInfoNextStep = async (cartId: number) => {
    return PatientInfoNextStepRequest(cartId)
      .then((res) => {
        ReactPixel.fbq("track", "Contact", {}, { eventID: "fb_contact" });

        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const QuestionnaireNextStep = async (cartId: number, values: {}) => {
    return QuestionnaireNextStepRequest(cartId, values)
      .then((res) => {
        ReloadCart();
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ProductDetailNextStep = async (cartId: number) => {
    return ProductDetailNextStepRequest(cartId)
      .then((res) => {
        ReactPixel.fbq("track", "AddToCart", {}, { eventID: "fb_add_to_cart" });
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ShippingNextStep = async (cartId: number, resetter: any) => {
    return ShippingNextStepRequest(cartId)
      .then((res) => {
        ReactPixel.fbq(
          "track",
          "CompleteRegistration",
          {},
          { eventID: "fb_complete_registration" }
        );
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
        rollbar.error(err);
      })
      .finally(() => {
        resetter();
      });
  };

  const IdUploadNextStep = async (cartId: number, skipId: boolean) => {
    return IdUploadNextStepRequest(cartId, skipId)
      .then((res) => {
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SelfieNextStep = async (cartId: number, skipSelfie: boolean) => {
    return SelfieNextStepRequest(cartId, skipSelfie)
      .then((res) => {
        navigate(res.data.redirect_path);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ApplyDiscount = async (values: { cart_id: number; code: string }) => {
    return ApplyDiscountRequest(values)
      .then((res) => {
        setLatestCart(res.data.cart);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const RemoveDiscount = async (cartId: number) => {
    return RemoveDiscountRequest(cartId)
      .then((res) => {
        setLatestCart(res.data.cart);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PreviousCartStep = async () => {
    const slug: string =
      latestCart?.product_variant?.slug + `?qty=${latestCart?.quantity}` || "";

    const pathData: {
      path?: string;
      state?: {
        intro_question_id?: number;
        isQuestionnaire?: boolean;
        canResetQuestionnaire?: boolean;
        editingPaths: boolean;
        cancelVisitRoute?: boolean;
      };
    } = CartPreviousPath(location, latestCart?.marketing || false, slug);

    if (!isEmpty(searchParams.get("redirect_uri"))) {
      navigate("/");
      return;
    }

    if (pathData.state?.editingPaths) {
      // To avoid updating cart previous step for editing paths
      navigate(`${pathData.path}`);
    } else {
      if (!isEmpty(latestCart)) {
        CartsPreviousStepRequest(latestCart?.id)
          .then((res) => {
            if (pathData.state?.canResetQuestionnaire) {
              ResetQuestionnaireRequest(latestCart?.visit_uuid)
                .then((res) => {
                  navigate(`${pathData.path}`, { replace: true });
                })
                .catch((err) => {
                  rollbar.error(err);
                  console.log(err);
                });
            } else if (pathData.state?.cancelVisitRoute) {
              navigate(`${pathData.path}`);
            } else if (pathData.state?.isQuestionnaire) {
              setShouldQuestionnaireBack(true);
            } else if (
              !isEmpty(pathData.state) &&
              pathData.state.intro_question_id
            ) {
              navigate(`${pathData.path}`, {
                state: pathData.state,
                replace: true,
              });
            } else if (pathData.path?.length === 0) {
              navigate(-1);
            } else if (
              res.data.redirect_path &&
              ![
                "/checkout/visit-intro",
                "/checkout/patient-info",
                "/checkout/visit-consent",
                "/intro-questions",
                "/welcome",
              ].includes(location.pathname)
            ) {
              navigate(`/${res.data.redirect_path}`, { replace: true });
            } else {
              navigate(`${pathData.path}`, { replace: true });
            }
          })
          .catch((err) => {
            rollbar.error(err);
            console.log(err);
          });
      } else if (!isEmpty(pathData.state) && pathData.state.intro_question_id) {
        navigate(`${pathData.path}`, {
          state: pathData.state,
          replace: true,
        });
      } else if (location.state?.loginWithEmail == true) {
        navigate("/");
      } else if (pathData.path?.length === 0) {
        navigate(-1);
      } else {
        navigate(`${pathData.path}`, { replace: true });
      }
    }
  };

  const context = {
    latestCart,
    cartProductVariant,
    shouldQuestionnaireBack,
    currentQuestionId,
    questionProgressBar,
    setLatestCart,
    setCartProductVariant,
    CreateCart,
    CreateSlugCart,
    UpdateCart,
    DestroyCart,
    ReloadCart,
    WelcomePage,
    SendIntroQuesToPocketmed,
    VisitConsentNextStep,
    VisitIntroNextStep,
    PatientInfoNextStep,
    QuestionnaireNextStep,
    ProductDetailNextStep,
    ShippingNextStep,
    IdUploadNextStep,
    SelfieNextStep,
    ApplyDiscount,
    RemoveDiscount,
    PreviousCartStep,
    setShouldQuestionnaireBack,
    setCurrentQuestionId,
    setQuestionProgressBar,
    MarketingNextStep,
  };

  return (
    <CartContext.Provider value={context}>{children}</CartContext.Provider>
  );
};

export default CartContextProvider;
